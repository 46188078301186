import { defineStore } from 'pinia';
import { until } from '@vueuse/core';
import { useCurrentOrg } from '@/modules/currentOrg/composables';
import { getModeLocalStorage, setModeLocalStorage } from './mode';
import { assertIsMode, type Mode } from './types';

export const useModeStore = defineStore('mode', () => {
  // null = not set, '' = set but has no mode (new user)
  const currentMode = ref<null | Mode | ''>(null);

  const { organisation } = useCurrentOrg();

  function setMode(mode: Mode) {
    setModeLocalStorage(mode);
    currentMode.value = mode;
  }

  watch(
    organisation,
    (org) => {
      if (!org) return;

      if (org.isFederated) {
        return setMode('federated');
      }

      // If local storage contains value matching supplier or client
      // Dispatch setMode action to update application state in the mode store
      if (getModeLocalStorage() === 'client' && org.isClient) return setMode('client');
      if (getModeLocalStorage() === 'supplier' && org.isSupplier) return setMode('supplier');

      // Otherwise if no value, check organisation type with client
      // taking precedence over supplier
      if (org.isClient) return setMode('client');
      if (org.isSupplier) return setMode('supplier');

      // Else organisation has no type yet and value will remain empty
      return setMode('');
    },
    { immediate: true },
  );

  return { currentMode, setMode };
});

/**
 * Wraps the pinia store to return a destructurable composable.
 */
export function useMode() {
  const modeStore = useModeStore();

  const currentMode = computed(() => modeStore.currentMode);
  const currentClientOrSupplierMode = computed(() => {
    if (currentMode.value === 'client' || currentMode.value === 'supplier') {
      return currentMode.value;
    }
    throw new Error('Mode is not client or supplier');
  });

  function setMode(mode: string) {
    assertIsMode(mode);
    modeStore.setMode(mode);
  }

  async function resolvedMode() {
    if (currentMode.value === null) {
      await until(currentMode).toMatch((c) => typeof c === 'string');
    }
    if (currentMode.value === null) throw new Error('this is impossible');
    return currentMode.value;
  }

  return { currentMode, resolvedMode, setMode, currentClientOrSupplierMode };
}

export function useIsRestrictedOrg() {
  const restrictedOrgs = [
    '2ed581a6-6ded-41de-ad5f-b191367780c6', // SafeBase
    'e87cb0a2-7892-490d-aa58-31f6ff2032d3', // ThirdPartyTrust
    'fb6813a8-505a-407e-ab55-ec0c81491c91', // OneTrust
    'b3fea387-150a-4cb6-b4ff-d72efb2aef0c', // UpGuard
    'aacc4702-bb97-4a3c-9dad-2d6c02af1d56', // Prevalent, Inc
    'd1a81066-472f-436d-9479-d5fb2bd38dd0', // Automox (has safebase user)
    '4255d1a7-553e-4ad0-9fb1-3e3698956316', // Loopip Inc
    '4198f536-3733-4706-af87-26fa97db3d5a', // Graphite Systems Inc
    'a1ce0356-0e57-487e-b2e9-ecec70ac973b', // CybelAngel
    'f0c5b90d-54c0-4465-ac75-07ad90e6e957', // Bitsight
  ];

  const { organisation } = useCurrentOrg();
  const isRestrictedOrg = computed(
    () =>
      organisation.value?.isClientRestricted && restrictedOrgs.includes(organisation.value.orgID),
  );

  return { restrictedOrgs, isRestrictedOrg };
}
