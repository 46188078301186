import type { RouteRecordRaw } from 'vue-router';
import { requireFlag } from '../../routes/guards/flag';
import { FeatureFlag } from '../../composables/feature-flags';

export default [
  {
    path: 'onboarding',
    name: 'plg-client-onboarding-checklist',
    alias: '/onboarding',
    component: () => import('./ClientOnboardingChecklist.vue'),
    beforeEnter: [requireFlag(FeatureFlag.PlgClientOnboardingChecklist)],
  },
] satisfies RouteRecordRaw[];
