export enum EngagementAssociatedType {
  User = 'USER',
  Organisation = 'ORGANISATION',
}

export enum EngagementType {
  AddMonitoringDomain = 'MONITORING_ADD_DOMAIN',
  MonitoringBenefits = 'MONITORING_BENEFITS',
  PLGHubSpotForm = 'PLG_HUBSPOT_FORM',
}

export type Engagement = {
  id: string;
  associatedType: EngagementAssociatedType;
  associatedID: string;
  engagementType: EngagementType;
  engagementCount: number;
  lastSessionID: string;
};
