import type { RouteRecordRaw } from 'vue-router';
import authSSOLoginRoutes from '@/modules/authSSOLogin/routes';
import authRoutes from '@/modules/auth/routes';
import clientReportingRoutes from '@/modules/clientReporting/routes';
import clientNetworkRoutes from '@/modules/clientNetwork/routes';
import clientOnboardingChecklistRoutes from '@/modules/plg-client-onboarding-checklist/routes';
import profileSharingPublicRoutes from '@/modules/profileSharingPublic/routes';
import businessApprovalPublicRoutes from '@/modules/businessApprovalPublic/routes';
import profileSharingLinksRoutes from '@/modules/profileSharingLinks/routes';
import clientDashboardRoutes from '@/modules/clientDashboard/routes';
import settingsRoutes from '@/modules/settings/routes';
import { discussionsClientRoutes, discussionsSupplierRoutes } from '@/modules/discussions/routes';
import {
  actionCentreClientRoutes,
  actionCentreSupplierRoutes,
} from '@/modules/actionCentre/routes';
import quickConnectRoutes from '@/modules/quickConnect/routes';
import networkGraphRoutes from '@/modules/networkGraph/routes';
import connectionViewSuppliersRoutes from '@/modules/connectionViewSuppliers/routes';
import connectionViewClientsRoutes from '@/modules/connectionViewClients/routes';
import zeusRoutes from '@/modules/zeus/routes';
import emergingThreatsSuppliersRoutes from '@/modules/emergingThreatsSuppliers/routes';
import emergingThreatsClientsRoutes from '@/modules/emergingThreatsClients/routes';
import clientTrialRoutes from '@/modules/trial/routes';
import { federatedRisksRoutes, risksRoutes } from '@/modules/risks/routes';
import federatedDashboardRoutes from '@/modules/federatedDashboard/routes';
import connectionViewSubEntitiesRoutes from '@/modules/connectionViewSubEntities/routes';
import federatedNetworkRoutes from '@/modules/federatedNetwork/routes';
import federatedSubEntitiesProfileRoutes from '@/modules/federatedSubEntitiesProfile/routes';
import documentsRoutes from '@/modules/documents/routes';
import communitiesRoutes from '@/modules/communities/routes';
import answerSearchRoutes from '@/modules/answerSearch/routes';
import { supplierMonitoringRoutes } from '@/modules/externalMonitoring/routes';

import { requireAuth } from '@/routes/guards/auth';
import { requireMode } from '@/routes/guards/mode';
import {
  requireClient,
  requireClientNotRestricted,
  requireFederated,
  requireSupplier,
} from '@/routes/guards/orgtype';

import { FeatureFlag } from '../composables/feature-flags';
import clientRoutes from './clients';
import assessmentRoutes from './assessment';
import supplierRoutes from './suppliers';
import noTypeRoutes from './noType';
import logoutRoutes from './logout';
import publicRoutes from './public';
import { redirectWithFlag } from './guards/flag';

export default [
  ...authRoutes,
  ...authSSOLoginRoutes,
  ...noTypeRoutes,
  ...logoutRoutes,
  ...profileSharingPublicRoutes,
  ...businessApprovalPublicRoutes,
  ...publicRoutes,
  {
    path: '/',
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        // This beforeEnter handles the redirect when / is accessed
        beforeEnter: requireMode,
        // This component isn't rendered, it's just here to make the redirect work
        component: () => import('@/components/layouts/AppSidebarLayout.vue'),
      },
      ...settingsRoutes,
    ],
  },
  {
    path: '/c',
    beforeEnter: [requireAuth, requireClient, requireClientNotRestricted],
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    meta: { mode: 'client' },
    redirect: redirectWithFlag(FeatureFlag.PlgClientOnboardingChecklist, {
      to: 'plg-client-onboarding-checklist',
      defaultTo: 'client-dashboard',
    }),
    children: [
      ...clientDashboardRoutes,
      ...clientReportingRoutes,
      ...clientNetworkRoutes,
      ...discussionsClientRoutes,
      ...actionCentreClientRoutes,
      ...connectionViewSuppliersRoutes,
      ...clientRoutes,
      ...quickConnectRoutes,
      ...emergingThreatsClientsRoutes,
      ...risksRoutes,
      ...communitiesRoutes,
      ...answerSearchRoutes,
      ...clientOnboardingChecklistRoutes,
    ],
  },
  {
    // This route is seperate as the requireClient route guard checks for trial status
    path: '/c',
    beforeEnter: [requireAuth],
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    meta: { mode: 'client' },
    redirect: redirectWithFlag(FeatureFlag.PlgClientOnboardingChecklist, {
      to: 'plg-client-onboarding-checklist',
      defaultTo: 'client-dashboard',
    }),
    children: clientTrialRoutes,
  },
  {
    path: '/s',
    beforeEnter: [requireAuth, requireSupplier],
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    meta: { mode: 'supplier' },
    redirect: { name: 'supplier-getting-started' },
    children: [
      ...discussionsSupplierRoutes,
      ...actionCentreSupplierRoutes,
      ...profileSharingLinksRoutes,
      ...connectionViewClientsRoutes,
      ...assessmentRoutes,
      ...supplierRoutes,
      ...emergingThreatsSuppliersRoutes,
      ...documentsRoutes,
      ...supplierMonitoringRoutes,
    ],
  },
  {
    path: '/f',
    beforeEnter: [requireAuth, requireFederated],
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    redirect: { name: 'federated-dashboard' },
    children: [
      ...federatedDashboardRoutes,
      ...federatedRisksRoutes,
      ...connectionViewSubEntitiesRoutes,
      ...federatedNetworkRoutes,
      ...federatedSubEntitiesProfileRoutes,
    ],
  },
  {
    path: '/zeus',
    name: 'zeus',
    beforeEnter: requireAuth, // TODO later: zeus guard/permissions? (2 hours)
    component: () => import('@/components/layouts/AppSidebarLayout.vue'),
    redirect: { name: 'zeus-orgs' },
    children: [...networkGraphRoutes, ...zeusRoutes],
  },
  {
    path: '/down-for-maintenance',
    name: 'down-for-maintenance',
    component: () => import('@/modules/errors/DownForMaintenance.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/modules/errors/NotFound.vue'),
  },
] satisfies RouteRecordRaw[];
