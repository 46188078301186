import type { MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import { isAuthenticatedByRLRef } from '@/modules/auth/auth';
import { useCurrentOrg, useOrgUsers } from '@/modules/currentOrg/composables';
import { ORG_USERS_QUERY } from '@/modules/currentOrg/composableKeys';
import type { Role } from '@/types/models/roles';
import type { CreateRoleBody, UpdateRoleBody, DeleteRole } from './api';
import api from './api';
import { CURRENT_ORG_ROLES_QUERY } from './composableKeys';

export function useCurrentOrgRoles(options?: QueryOptions<Role[]>) {
  const { organisation: currentOrg } = useCurrentOrg();
  const { orgUsers } = useOrgUsers();

  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [CURRENT_ORG_ROLES_QUERY],
      async queryFn() {
        const res = await api.getOrgRoles();
        return res.data;
      },
      enabled: isAuthenticatedByRLRef,
    }),
  );

  const { t } = useI18n();

  const roleLabel = (roleId: string | undefined, isLead = false) => {
    if (isLead) return t('roles.lead');
    if (!roleId) return t('roles.unknown');
    const role = query.data.value?.find((r) => r.id === roleId);
    if (!role) return t('roles.unknown');
    return role.orgID ? role.name : t(`roles.${role.name}`);
  };

  const roles = computed<Role[]>(
    () =>
      query.data.value
        ?.filter((role) => {
          if (role.orgID) return true;
          if (Object.values(orgUsers.value).find(({ roleId }) => roleId === role.id)) return true;
          return (
            (!role.name.includes('client') && !role.name.includes('supplier')) ||
            (currentOrg.value?.isClient && currentOrg.value?.isSupplier)
          );
        })
        .sort((a, b) => {
          if (a.orgID) {
            if (!b.orgID) return 1;
            return a.createdAt < b.createdAt ? -1 : 1;
          }
          if (b.orgID) return -1;
          return roleLabel(a.id).toLowerCase() < roleLabel(b.id).toLowerCase() ? -1 : 1;
        }) || [],
  );

  return { ...query, roles, roleLabel };
}

export function useCreateRole(
  options?: MutationObserverOptions<Role, AxiosError, CreateRoleBody, null>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(role) {
        const res = await api.createRole(role);
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([CURRENT_ORG_ROLES_QUERY], (oldData: Role[] | undefined) => {
          if (!oldData) return;
          return [...oldData, data];
        });
      },
    }),
  );
}

export function useUpdateRole(
  options?: MutationObserverOptions<Role, AxiosError, { id: string; role: UpdateRoleBody }, null>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ id, role }) {
        const res = await api.updateRole(id, role);
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([CURRENT_ORG_ROLES_QUERY], (oldData: Role[] | undefined) =>
          oldData?.map((oldRole) => (oldRole.id === data.id ? data : oldRole)),
        );
      },
    }),
  );
}

export function useDeleteRole(
  options?: MutationObserverOptions<null, AxiosError, DeleteRole, null>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(deleteRole) {
        const res = await api.deleteRole(deleteRole.roleID, deleteRole.replacementRoleID);
        return res.data;
      },
      onSuccess(_, { roleID, replacementRoleID }) {
        queryClient.setQueryData([CURRENT_ORG_ROLES_QUERY], (oldData: Role[] | undefined) => {
          if (!oldData) return;
          return oldData.filter((role) => role.id !== roleID);
        });
        if (replacementRoleID) {
          queryClient.invalidateQueries({ queryKey: [ORG_USERS_QUERY] });
        }
      },
    }),
  );
}
