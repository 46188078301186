<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router';
import SimpleLayoutWithLogo from '@/components/layouts/SimpleLayoutWithLogo.vue';
import { useCurrentOrg, useOrgUsers } from '@/modules/currentOrg/composables';
import { useCurrentOrgRoles } from '@/modules/roles/composables';
import useHasPermissions from '@/composables/permissions';
import { useMode } from '@/modules/mode/composables';

const { Permission, hasClientPermissions, hasSupplierPermissions } = useHasPermissions();

const route = useRoute();
const { organisation } = useCurrentOrg();
const permissionsKeys = computed(() => {
  const permissions = (route.meta.permissions as string[] | undefined) || [];
  if (!permissions) return [];

  const permissionEntries = Object.entries(Permission);

  return permissions
    .filter((permission) => {
      if (permission.startsWith('clients') && !organisation.value?.isClient) return false;
      if (permission.startsWith('suppliers') && !organisation.value?.isSupplier) return false;
      if (permission.startsWith('federated') && !organisation.value?.isFederated) return false;
      return true;
    })
    .map((permission) => permissionEntries.find(([_key, value]) => value === permission)?.[0]);
});

const { roles } = useCurrentOrgRoles();
const { orgUsers } = useOrgUsers();
const adminUsers = computed(() => {
  const adminRole = roles.value.find(({ name }) => ['admin', 'federated_admin'].includes(name))!;
  return Object.values(orgUsers.value).filter(({ roleId }) => roleId === adminRole?.id);
});

const router = useRouter();
const { setMode } = useMode();
function goBack() {
  if (hasClientPermissions.value && !hasSupplierPermissions.value) {
    setMode('client');
    router.push('/c/');
  } else if (hasSupplierPermissions.value && !hasClientPermissions.value) {
    setMode('supplier');
    router.push('/s/');
  } else if (hasSupplierPermissions.value && hasClientPermissions.value) {
    // Has permission to both modes
    router.push('/n');
  } else {
    // Has access to no mode
    router.push('/settings');
  }
}
</script>

<template>
  <SimpleLayoutWithLogo>
    <div class="flex flex-col gap-3x rounded border p-8x text-base">
      <BaseHeading size="md" :level="2">
        {{ $t('errorScreens.noPermissions.title') }}
      </BaseHeading>
      <p class="m-0">{{ $t('errorScreens.noPermissions.body1') }}</p>

      <blockquote class="m-0 space-y-1x rounded border p-6x font-bold">
        <div v-for="permission in permissionsKeys" :key="permission">
          {{ $t(`roles.permissions.${permission}.title`) }}
        </div>
      </blockquote>

      <p class="m-0">{{ $t('errorScreens.noPermissions.body2') }}</p>

      <div class="flex flex-wrap gap-2x">
        <BaseLabel v-for="user in adminUsers" :key="user.id">{{ user.fullName }}</BaseLabel>
      </div>

      <div class="flex justify-end">
        <BaseButton type="button" colour="primary" filled @click="goBack">
          {{ $t('errorScreens.noPermissions.backToPlatform') }}
        </BaseButton>
      </div>
    </div>
  </SimpleLayoutWithLogo>
</template>
